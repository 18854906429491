var __jsx = React.createElement;
import React from 'react';
import { useRouter } from 'next/router';

var Home = function Home() {
  var router = useRouter();
  React.useEffect(function () {
    router.replace('/tab');
  }, []);
  return __jsx(React.Fragment, null);
};

export default Home;